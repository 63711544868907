import React from 'react'
import Inner from '../Inner'

function DelivraTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                <img src={'/images/delivra/logo-delivra.svg'} alt='Delivra' className='logo' />
                {data.text && <p>{data.text}</p>}
            </div>
        </Inner>
    )
}

export default DelivraTemplate
