import classNames from 'classnames'
import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'

import Layout from '../components/Layout'
import Nav from '../components/Nav'
import SEO from '../components/Seo'
import Template from '../components/Templates'

const SEL = 'slide'
const SECTION_SEL = `.${SEL}`

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {
  /*
   * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
   */
}

const sections = [
  ['Who We Are', 'who-we-are'],
  ['What We Do', 'what-we-do'],
  ['How We Do It', 'how-we-do-it'],
  ['Why We Do It', 'why-we-do-it'],
]

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlide: '',
      fullpages: [
        {
          anchor: '',
          template: 'intro',
          templateData: {
            title: 'Culture Manual',
          },
        },
        {
          anchor: 'manifesto',
          template: 'solidDark',
          templateClass: '--manifesto',
          templateData: {
            title: 'Our Manifesto',
            text:
              'They say how we spend our days is how we spend our lives. At Redbrick, we spend our days together, so we believe people are our most valuable investment—and creating an awesome culture for them? That’s non-negotiable.',
          },
        },
        {
          anchor: 'collage',
          navClass: '--gradient',
          template: 'backgroundImage',
          templateClass: '--collage',
        },
        {
          anchor: 'who-we-are',
          navClass: '--gradient',
          section: 'who-we-are',
          template: 'backgroundImage',
          templateClass: '--who-we-are',
          templateData: {
            subtitle: 'Who We Are',
            showLogo: true,
          },
        },
        {
          anchor: 'meet-redbrick',
          section: 'who-we-are',
          template: 'solidDark',
          templateData: {
            title: 'Meet Redbrick',
            subtitle: 'The backbone of disruptive digital companies',
            text:
              'We’re the founders of a portfolio of disruptive digital companies. Headquartered in Victoria, British Columbia, Canada, we’ve scaled from local start-up to global name in a few short years—all thanks to the people we invest in.',
          },
        },
        {
          anchor: 'screens',
          navClass: '--gradient',
          section: 'who-we-are',
          template: 'backgroundImage',
          templateClass: '--screens',
        },
        {
          anchor: 'backstory',
          section: 'who-we-are',
          template: 'backstory',
          templateData: {
            title: 'The Backstory',
            subtitle: 'Growth and change',
            text:
              'Founded and led by CEO, Tobyn Sowden, Redbrick began as a small marketing services company in an intimate, red-brick office. As the company grew and the industry changed, we did, too. Now, we conceive, build, acquire, and grow products and companies that empower the digital entrepreneur.',
          },
        },
        {
          anchor: 'tobyn-quote',
          section: 'who-we-are',
          template: 'quote',
          templateData: {
            quote:
              'Redbrick is a group of amazingly talented individuals that come together to form a team that builds and grows strong digital companies. As a team, we win together.',
            author: 'Tobyn Sowden, CEO',
          },
        },
        {
          anchor: 'our-audience',
          navClass: '--gradient',
          section: 'who-we-are',
          template: 'backgroundImage',
          templateClass: '--our-audience',
          templateData: {
            title: 'Our Audience',
            subtitle: 'Every minute, 2,964 people engage with our products',
            text:
              'In building a PROFIT 500 award-winning business, we’ve amassed a global following: Today, we serve people across 20 countries (and counting). But we only innovate products we know will add value to their work and lives—with a knack for getting them in front of the people who want them most.',
          },
        },
        {
          anchor: 'what-we-do',
          navClass: '--gradient',
          section: 'what-we-do',
          template: 'backgroundImage',
          templateClass: '--what-we-do',
          templateData: {
            subtitle: 'What We Do',
            showLogo: true,
          },
        },
        {
          anchor: 'our-mission',
          section: 'what-we-do',
          template: 'solidDark',
          templateData: {
            title: 'Our Mission',
            subtitle: 'We add value',
            text:
              'Sure, we’re known as an innovative technology company, developing products that enable our audience to do what we’ve done for ourselves: Work smarter, not harder. But it doesn’t stop there.',
          },
        },
        {
          anchor: 'value',
          section: 'what-we-do',
          template: 'backgroundImage',
          templateClass: '--value',
          templateData: {
            title:
              'At the end of the day, everything we do is about adding value to the work and lives of our audience and our team. We wouldn’t have it any other way.',
            text:
              'After all, we’re entrepreneurs first: We thrive on the hustle, but we play as hard as we work. And we think working where you feel both at home and challenged is pretty damn valuable. And each of our portfolio companies presents its own dynamic, challenging opportunities.',
          },
        },
        /*
                {
                    anchor: 'video',
                    section: 'what-we-do',
                    template: 'video',
                    templateData: {
                        videoSrcMP4: '/videos/video.mp4',
                        videoSrcWEBM: '/videos/video.webm',
                    },
                },
                */
        {
          anchor: 'portfolio',
          section: 'what-we-do',
          template: 'portfolio',
          templateData: {
            subtitle: 'Our Portfolio of Companies',
          },
        },
        {
          anchor: 'shift',
          navClass: '--inverted',
          section: 'what-we-do',
          template: 'shift',
          templateData: {
            text:
              'Shift is our desktop productivity app. Tired of logging in and out of accounts and toggling between tabs, we created Shift to manage your accounts, streamline your workflows, and blow up your productivity.',
          },
        },
        {
          anchor: 'rebase',
          navClass: '--inverted',
          section: 'what-we-do',
          template: 'rebase',
          templateData: {
            text:
              'Rebase is our browser development division and legacy business. We customize Chromium’s open-source browser to create user-centric desktop and mobile browsers that reimagine the web experience.',
          },
        },
        {
          anchor: 'leadpages',
          navClass: '--inverted',
          section: 'what-we-do',
          template: 'leadpages',
          templateData: {
            text:
              'Distinguished by built-in marketing guidance and best-in-class customer success, Leadpages equips digital entrepreneurs to build websites that convert and grow their businesses online.',
          },
        },
        {
          anchor: 'delivra',
          navClass: '--inverted',
          section: 'what-we-do',
          template: 'delivra',
          templateData: {
            text:
              'The newest addition to the Redbrick portfolio, Delivra provides advanced email marketing and automation for any level of marketer or entrepreneur.',
          },
        },
        {
          anchor: 'how-we-do-it',
          navClass: '--gradient',
          section: 'how-we-do-it',
          template: 'backgroundImage',
          templateClass: '--how-we-do-it',
          templateData: {
            subtitle: 'How We Do It',
            showLogo: true,
          },
        },
        {
          anchor: 'company',
          navClass: '--gradient',
          section: 'how-we-do-it',
          template: 'ourCompany',
          templateData: {
            title: 'Our Company',
            subtitle: 'We know how to share',
            text:
              'We know what you’re thinking: Four portfolio companies under one roof—how does it work? We’ve learned how to divide and conquer with a focused team for each portfolio company, but we share everything else, from our leadership to our marketing team.',
          },
        },
        {
          anchor: 'brand',
          navClass: '--inverted',
          section: 'how-we-do-it',
          template: 'brand',
          templateData: {
            title: 'Our Brand',
            nameTitle: 'The Name',
            nameSubtitle: 'Redbrick',
            nameText:
              'The Redbrick name is a nod to our beginnings in one of Victoria’s historic heritage buildings and its original red-brick walls. If you haven’t noticed, it’s all one word, and the ‘R’ is capitalized, but ‘b’ is not.',
            urlTitle: 'The URL',
            urlSubtitle: 'rdbrck.com',
            urlText:
              'If Wheel of Fortune taught us anything, it’s that vowels are pricey. Playing to the trend of vowel-less URLs, we dropped ours in 2018.',
          },
        },
        {
          anchor: 'elevator-pitch',
          navClass: '--gradient',
          section: 'how-we-do-it',
          template: 'elevatorPitch',
          templateClass: '--elevator-pitch',
          templateData: {
            title: 'The Elevator Pitch',
            subtitle:
              'Because sometimes there’s a time limit on explaining what you do—and we want to make it as simple as possible for peers (and, ahem, parents) to understand where we work.',
            quote:
              'Redbrick is the backbone of a portfolio of disruptive digital companies: Shift, Leadpages, Delivra and Rebase. Whether we’re conceiving and building products in-house, or acquiring and scaling companies, we know how to get our products in front of the people who want them most. I’m a member of Redbrick’s ____________ team.',
          },
        },
        {
          anchor: 'why-we-do-it',
          navClass: '--gradient',
          section: 'why-we-do-it',
          template: 'backgroundImage',
          templateClass: '--why-we-do-it',
          templateData: {
            subtitle: 'Why We Do It',
            showLogo: true,
          },
        },
        {
          anchor: 'people',
          section: 'why-we-do-it',
          template: 'solidDark',
          templateData: {
            title: 'Our People',
            subtitle: 'The backbone of Redbrick',
            text:
              'Redbrick may be the backbone of some awesome companies, but we couldn’t do it without some pretty awesome people. These are the people who innovate in the boardroom and cheers in the breakroom, who take the “work hard, play hard” mentality to the next level. These are Redbrickers, and they’re why we do what we do.',
          },
        },
        {
          anchor: 'our-dna',
          section: 'why-we-do-it',
          template: 'dna',
          templateData: {
            title: 'Our DNA',
            subtitle: 'What we’re made of',
            text:
              'Every Redbricker has three things in common: We’re good-humoured, curious and hungry (for results, but we’ll never say no to a donut, either). We try our best to balance hard work with good, old-fashioned fun because good times taste sweeter when you’ve worked for them. We’re self-starters and team-players, so you’ll find no shortage of go-getters here.',
          },
        },
        {
          anchor: 'shift-quote',
          navClass: '--gradient',
          section: 'why-we-do-it',
          template: 'quote',
          templateClass: '--shift-quote',
          templateData: {
            quote:
              'Working at Shift has been a very rewarding experience both personally and professionally. Challenging problems are met with a collaborative mindset and mentorship from some exceptionally talented people. All of this cumulates in a culture that is supportive, fun, hardworking and highly productive.',
            author: 'Carl, Shift',
          },
        },
        {
          anchor: 'our-values',
          navClass: '--inverted',
          section: 'why-we-do-it',
          template: 'solidWhite',
          templateData: {
            title: 'Our Values',
            subtitle: 'We live our values',
            text:
              'Like any solid relationship, our likes and dislikes are as different as backcountry skiing and Final Fantasy, but our values are the same: We’re all here because we thrive on the hustle of entrepreneurial life—without the personal sacrifice. We work hard as a team and pride ourselves on the quality of that work. But we know it’s nothing without fun, too.',
          },
        },
        {
          anchor: 'our-values-2',
          section: 'why-we-do-it',
          template: 'ourValues',
        },
        {
          anchor: 'our-culture',
          navClass: '--gradient',
          section: 'why-we-do-it',
          template: 'backgroundImage',
          templateClass: '--our-culture',
          templateData: {
            title: 'Our Culture',
            subtitle: 'This is your second family',
            text:
              "We spend a lot of time at work, whether in person or collaborating virtually, and therefore truly believe that you should enjoy what you work on, and who you work with. We cheerlead each other's goals and accomplishments, and are invested in your career growth.",
            textSecondary:
              'Flexible work hours, bike-to-work incentives, and professional development funds are a few of the many benefits, perks, and beyond of working at Redbrick. Plus, with regular team events both in person and virtually, work does feel a little more like your second family.',
          },
        },
        {
          anchor: 'perks',
          navClass: '--gradient --inverted',
          section: 'why-we-do-it',
          template: 'perks',
          templateData: {
            title: 'The Perks',
          },
        },
        {
          anchor: 'rebase-quote',
          navClass: '--gradient',
          section: 'why-we-do-it',
          template: 'quote',
          templateClass: '--rebase-quote',
          templateData: {
            quote: '[Redbrick is] the 4 Fs: fun, fulfilling, fast-paced and flexible!',
            author: 'Tina, Rebase',
          },
        },
        {
          anchor: 'our-space',
          navClass: '--gradient',
          section: 'why-we-do-it',
          template: 'backgroundImage',
          templateClass: '--our-space',
          templateData: {
            title: 'Our Team',
            subtitle: 'Where We Work',
            text:
              'We thrive as a hybrid team. Some team members collaborate from our beautiful offices in Victoria, BC and Minneapolis, MN, while others work remotely. We are proud to have a diverse group of team members across multiple provinces and states, and the tools to work together, wherever that may be from.',
          },
        },
        // {
        //   anchor: 'our-building',
        //   navClass: '--gradient',
        //   section: 'why-we-do-it',
        //   template: 'ourBuilding',
        //   templateData: {
        //     title: 'The Building',
        //   },
        // },
        // {
        //   anchor: 'our-city',
        //   navClass: '--gradient',
        //   section: 'why-we-do-it',
        //   template: 'ourCity',
        //   templateData: {
        //     title: 'Our City',
        //     subtitle: 'West Coast, best coast',
        //     col1:
        //       'Headquartered in Victoria, British Columbia, Canada, this is where work and play are best friends. We’re known as Tectoria, thanks to our booming tech industry, but we’ve got all the adventure you could ask for, from epic hikes and gnarly surf to museums and historical sites, plus a busy farm-to-table food scene.',
        //     col2:
        //       'We’re the Goldilocks of cities: Not too big, not too small. It only takes 20 minutes to drive anywhere (but we’re avid cyclists, too, thanks to all the bike lanes and trails snaking through our city). And ours is the most temperate climate in Canada because, well, we live in a rainforest. No -20ºC winters, thank you very much.',
        //     outro: 'In short, we think you’ll like it here.',
        //   },
        // },
        {
          anchor: 'conclusion',
          template: 'outro',
          templateData: {
            title: 'It’s official:<br/>Welcome to the family.',
          },
        },
      ],
      showMenu: false,
    }
  }

  resolveCurrentSlide(currentAnchor) {
    const fullpages = this.state
    const obj = fullpages.fullpages.find((slide) => slide.anchor === currentAnchor)
    return obj
  }

  afterLoad(anchor, index) {
    if (index.anchor && index.anchor === 'company') {
      if (typeof window !== 'undefined') {
        window.fullpage_api.reBuild()
      }
    }
  }

  onLeave(origin, destination, direction) {
    this.setState({ currentSlide: this.resolveCurrentSlide(destination.anchor) })
    if (destination.isFirst || destination.isLast) {
      this.setState({ visible: false })
    } else {
      this.setState({ visible: true })
    }
    if (origin.anchor === 'company' && typeof window !== 'undefined') {
      // company always starts from the top when user navigates back
      setTimeout(function() {
        window.fp_scrolloverflow.iscrollHandler.iScrollInstances.forEach(function(instance) {
          if (instance.wrapper.closest('.slide')) {
            if (instance.wrapper.closest('.slide').getAttribute('data-anchor') === 'company') {
              instance.scrollTo(0, 0)
            }
          }
        })
      }, 1000)
    }
  }

  render() {
    const { currentSlide, fullpages, visible } = this.state

    if (!fullpages.length) {
      return null
    }

    return (
      <Layout>
        <SEO />
        <Nav currentSlide={currentSlide} sections={sections} visible={visible} />
        {typeof window !== 'undefined' && (
          <ReactFullpage
            //to avoid problems with css3 transforms and fixed elements in Chrome, as detailed here: https://github.com/alvarotrigo/fullPage.js/issues/208
            css3={false}
            // debug /* Debug logging */
            // Required when using extensions
            pluginWrapper={pluginWrapper}
            // fullpage options
            licenseKey={'0AF97765-84A1455C-8B5BF2A4-567D7A90'} // Get one from https://alvarotrigo.com/fullPage/pricing/
            //navigation
            anchors={fullpages.map((a) => a.anchor)}
            sectionSelector={SECTION_SEL}
            afterLoad={this.afterLoad.bind(this)}
            onLeave={this.onLeave.bind(this)}
            scrollOverflow={true}
            render={(comp) => (
              <ReactFullpage.Wrapper>
                {fullpages.map((slide, index) => (
                  <div
                    key={`slide-${index}`}
                    className={classNames(
                      SEL,
                      `--slide-${index}`,
                      slide.template !== undefined ? `--template-${slide.template}` : '--template-default',
                      slide.templateClass
                    )}
                  >
                    <Template slide={slide} />
                  </div>
                ))}
              </ReactFullpage.Wrapper>
            )}
          />
        )}
      </Layout>
    )
  }
}

export default App
