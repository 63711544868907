import React from 'react'
import Inner from '../Inner'

function AssemblyTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                <img src={'/images/assembly/logo-assembly.svg'} alt='Assembly' className='logo' />
                {data.text && <p>{data.text}</p>}
            </div>
        </Inner>
    )
}

export default AssemblyTemplate
