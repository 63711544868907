import React from 'react'
import Inner from '../Inner'

function ElevatorPitchTemplate({ data = {}}) {
    return (
        <>
            <Inner>
                <div className='colLeft'>
                    {data.title && <h2>{data.title}</h2>}
                    {data.subtitle && <p>{data.subtitle}</p>}
                </div>
                <div className='colRight'>
                    {data.quote && <p className='--quote'>{`\u201C${data.quote}\u201D`}</p>}
                </div>
            </Inner>
        </>
    )
}

export default ElevatorPitchTemplate
