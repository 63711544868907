import React from 'react'
import Inner from '../Inner'

function OurCityTemplate({ data = {}}) {
    return (
        <>
            <Inner>
                {data.title && <h2>{data.title}</h2>}
                {data.subtitle && <h1>{data.subtitle}</h1>}
                <div className="cols">
                    <div className='col'><p>{data.col1}</p></div>
                    <div className='col'><p>{data.col2}</p></div>
                </div>
                <p className="outro">{data.outro}</p>
            </Inner>
        </>
    )
}

export default OurCityTemplate
