import React from 'react'
import Inner from '../Inner'

function ShiftTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                <img src={'/images/shift/logo-shift.svg'} alt='Shift' className='logo' />
                {data.text && <p>{data.text}</p>}
            </div>
        </Inner>
    )
}

export default ShiftTemplate
