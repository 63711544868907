import React from 'react'
import Inner from '../Inner'

function BackgroundImageTemplate({ data = {} }) {
  const onGoToFirstPage = (anchor) => {
    if (typeof window !== 'undefined') {
      window.fullpage_api.moveTo('')
    }
  }

  return (
    <>
      <Inner>
        {data.title && <h2>{data.title}</h2>}
        {data.subtitle && <h1>{data.subtitle}</h1>}
        {data.text && <p>{data.text}</p>}
        {data.textSecondary && <p>{data.textSecondary}</p>}
      </Inner>
      {data.showLogo && (
        <button href={`#`} onClick={() => onGoToFirstPage()}>
          <img src={'/images/logo.svg'} alt='Logo' className='logo' />
        </button>
      )}
    </>
  )
}

export default BackgroundImageTemplate
