import React from 'react'
import Inner from '../Inner'

function OurCompanyTemplate({ data }) {
  return (
    <>
      <Inner className='intro'>
        {data.title && <h2>{data.title}</h2>}
        {data.subtitle && <h1>{data.subtitle}</h1>}
        {data.text && <p>{data.text}</p>}
      </Inner>
      <section className='team team_leaders'>
        <div className='inner'>
          <div className='box'>
            <h2>Leadership</h2>
            <p>
              From our CEO to our directors and general managers, these are the big thinkers, the brains behind every
              investment, acquisition, and decision that informs our companies’ strategic directions.
            </p>
          </div>
          <div className='people_wrap'>
            <div className='people'>
              <div className='person'>
                <picture>
                  <source
                    media='(max-width: 479px)'
                    srcSet='/images/our-team/tobyn-150.jpg 1x, /images/our-team/tobyn-300.jpg 2x'
                  />
                  <source
                    media='(min-width: 480px)'
                    srcSet='/images/our-team/tobyn-220.jpg 1x, /images/our-team/tobyn-440.jpg 2x'
                  />
                  <img src='/images/our-team/tobyn-220.jpg' alt='CEO' />
                </picture>
                <div className='desc'>
                  <p className='name'>Tobyn Sowden</p>
                  <p className='desc'>CEO</p>
                </div>
              </div>
              <div className='person'>
                <picture>
                  <source
                    media='(max-width: 479px)'
                    srcSet='/images/our-team/marco-150.jpg 1x, /images/our-team/marco-300.jpg 2x'
                  />
                  <source
                    media='(min-width: 480px)'
                    srcSet='/images/our-team/marco-220.jpg 1x, /images/our-team/marco-440.jpg 2x'
                  />
                  <img src='/images/our-team/marco-220.jpg' alt='CMO' />
                </picture>
                <div className='desc'>
                  <p className='name'>Marco Pimentel</p>
                  <p className='desc'>CMO</p>
                </div>
              </div>
              <div className='person'>
                <picture>
                  <source
                    media='(max-width: 479px)'
                    srcSet='/images/our-team/rory-150.jpg 1x, /images/our-team/rory-300.jpg 2x'
                  />
                  <source
                    media='(min-width: 480px)'
                    srcSet='/images/our-team/rory-220.jpg 1x, /images/our-team/rory-440.jpg 2x'
                  />
                  <img src='/images/our-team/rory-220.jpg' alt='COO' />
                </picture>
                <div className='desc'>
                  <p className='name'>Rory Capern</p>
                  <p className='desc'>COO</p>
                </div>
              </div>
              <div className='person'>
                <picture>
                  <source
                    media='(max-width: 479px)'
                    srcSet='/images/our-team/linda-150.jpg 1x, /images/our-team/linda-300.jpg 2x'
                  />
                  <source
                    media='(min-width: 480px)'
                    srcSet='/images/our-team/linda-220.jpg 1x, /images/our-team/linda-440.jpg 2x'
                  />
                  <img src='/images/our-team/linda-220.jpg' alt='VP of Finance' />
                </picture>
                <div className='desc'>
                  <p className='name'>Linda Christie</p>
                  <p className='desc'>VP of Finance</p>
                </div>
              </div>
              <div className='person'>
                <picture>
                  <source
                    media='(max-width: 479px)'
                    srcSet='/images/our-team/christine-150.jpg 1x, /images/our-team/christine-300.jpg 2x'
                  />
                  <source
                    media='(min-width: 480px)'
                    srcSet='/images/our-team/christine-220.jpg 1x, /images/our-team/christine-440.jpg 2x'
                  />
                  <img src='/images/our-team/christine-220.jpg' alt='VP of People &amp; Culture' />
                </picture>
                <div className='desc'>
                  <p className='name'>Christine Tatham</p>
                  <p className='desc'>VP of People &amp; Culture</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='team team_divisions'>
        <div className='inner'>
          <div className='people_wrap'>
            <div className='division_people_wrap shift'>
              <div className='division_logo'>
                <img src='/images/portfolio/logo-shift.svg' alt='Shift' />
              </div>
              <div className='people'>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/nadia-150.jpg 1x, /images/our-team/nadia-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/nadia-220.jpg 1x, /images/our-team/nadia-440.jpg 2x'
                    />
                    <img src='/images/our-team/nadia-220.jpg' alt='GM, Shift' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Nadia Tatlow</p>
                    <p className='desc'>CEO</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/mike-150.jpg 1x, /images/our-team/mike-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/mike-220.jpg 1x, /images/our-team/mike-440.jpg 2x'
                    />
                    <img src='/images/our-team/mike-220.jpg' alt='Director of Products, Shift' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Michael Foucher</p>
                    <p className='desc'>Director of Products</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/simon-150.png 1x, /images/our-team/simon-300.png 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/simon-220.png 1x, /images/our-team/simon-440.png 2x'
                    />
                    <img src='/images/our-team/simon-220.png' alt='Director of Marketing, Shift' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Simon Vreeswijk</p>
                    <p className='desc'>Director of Marketing</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='division_people_wrap leadpages'>
              <div className='division_logo'>
                <img
                  srcSet='/images/our-team/team-leadpages.png 1x, /images/our-team/team-leadpages@2x.png 2x'
                  src='/images/our-team/team-leadpages.png'
                  alt='Leadpages'
                />
              </div>
              <div className='people'>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/jeanette-150.jpg 1x, /images/our-team/jeanette-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/jeanette-220.jpg 1x, /images/our-team/jeanette-440.jpg 2x'
                    />
                    <img src='/images/our-team/jeanette-220.jpg' alt='CEO, Leadpages' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Jeanette Dorazio</p>
                    <p className='desc'>CEO</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/tyler-150.jpg 1x, /images/our-team/tyler-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/tyler-220.jpg 1x, /images/our-team/tyler-440.jpg 2x'
                    />
                    <img src='/images/our-team/tyler-220.jpg' alt='Director of Customer Support, Leadpages' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Tyler Tabako</p>
                    <p className='desc'>Senior Director of Customer Success & Operations</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/dave-150.jpg 1x, /images/our-team/dave-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/dave-220.jpg 1x, /images/our-team/dave-440.jpg 2x'
                    />
                    <img src='/images/our-team/dave-220.jpg' alt='Manager, Product, Leadpages' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Dave Firnstahl</p>
                    <p className='desc'>Director, Product Management</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/brian-150.jpeg 1x, /images/our-team/brian-300.jpeg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/brian-220.jpeg 1x, /images/our-team/brian-440.jpeg 2x'
                    />
                    <img src='/images/our-team/brian-220.jpeg' alt='Director, Software Engineering' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Brian Draijer</p>
                    <p className='desc'>Director, Software Engineering</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/ryan-150.jpg 1x, /images/our-team/ryan-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/ryan-220.jpg 1x, /images/our-team/ryan-440.jpg 2x'
                    />
                    <img src='/images/our-team/ryan-220.jpg' alt='Vice President of Technology, Leadpages' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Ryan Truax</p>
                    <p className='desc'>Senior Director, Marketing</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/jes-150.jpg 1x, /images/our-team/jes-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/jes-220.jpg 1x, /images/our-team/jes-440.jpg 2x'
                    />
                    <img src='/images/our-team/jes-220.jpg' alt='Vice President of Technology, Leadpages' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Jes Lahay</p>
                    <p className='desc'>Director, Product Design</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='division_people_wrap rebase'>
              <div className='division_logo'>
                <img
                  srcSet='/images/our-team/team-rebase.png 1x, /images/our-team/team-rebase@2x.png 2x'
                  src='/images/our-team/team-rebase.png'
                  alt='Rebase'
                />
              </div>
              <div className='people'>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/neil-150.jpg 1x, /images/our-team/neil-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/neil-220.jpg 1x, /images/our-team/neil-440.jpg 2x'
                    />
                    <img src='/images/our-team/neil-220.jpg' alt='CEO, Rebase' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Neil Henderson</p>
                    <p className='desc'>CEO</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/tina-150.jpg 1x, /images/our-team/tina-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/tina-220.jpg 1x, /images/our-team/tina-440.jpg 2x'
                    />
                    <img src='/images/our-team/tina-220.jpg' alt='Director of Marketing, Rebase' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Tina Chien</p>
                    <p className='desc'>Director of Marketing</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/conrad-150.jpg 1x, /images/our-team/conrad-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/conrad-220.jpg 1x, /images/our-team/conrad-440.jpg 2x'
                    />
                    <img src='/images/our-team/conrad-220.jpg' alt='Director of Technology, Rebase' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Conrad Foucher</p>
                    <p className='desc'>Director of Technology</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/david-150.jpg 1x, /images/our-team/david-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/david-220.jpg 1x, /images/our-team/david-440.jpg 2x'
                    />
                    <img src='/images/our-team/david-220.jpg' alt='Director of Ad Operations, Rebase' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>David buchanan</p>
                    <p className='desc'>Director of Ad Operations</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='division_people_wrap delivra'>
              <div className='division_logo'>
                <img
                  srcSet='/images/our-team/team-delivra.png 1x, /images/our-team/team-delivra@2x.png 2x'
                  src='/images/our-team/team-delivra.png'
                  alt='Delivra'
                />
              </div>
              <div className='people'>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/barb-150.jpg 1x, /images/our-team/barb-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/barb-220.jpg 1x, /images/our-team/barb-440.jpg 2x'
                    />
                    <img src='/images/our-team/barb-220.jpg' alt='CEO, Delivra' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Barbara Barry</p>
                    <p className='desc'>CEO</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/samantha-150.jpg 1x, /images/our-team/samantha-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/samantha-220.jpg 1x, /images/our-team/samantha-440.jpg 2x'
                    />
                    <img src='/images/our-team/samantha-220.jpg' alt='Director of Customer Success, Delivra' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Samantha o’Conner</p>
                    <p className='desc'>Director of Customer Success</p>
                  </div>
                </div>
                <div className='person'>
                  <picture>
                    <source
                      media='(max-width: 479px)'
                      srcSet='/images/our-team/madeline-150.jpg 1x, /images/our-team/madeline-300.jpg 2x'
                    />
                    <source
                      media='(min-width: 480px)'
                      srcSet='/images/our-team/madeline-220.jpg 1x, /images/our-team/madeline-440.jpg 2x'
                    />
                    <img src='/images/our-team/madeline-220.jpg' alt='Director of Marketing, Delivra' />
                  </picture>
                  <div className='desc'>
                    <p className='name'>Madeline Blasberg</p>
                    <p className='desc'>Director of Marketing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='inner'>
            <div className='box outro'>
              <h2>Shared Services Team</h2>
            </div>
            <div class='outro_cols'>
              <div class='box'>
                <h3>People & Culture</h3>
                <p>
                  We’re people people, and our People & Culture team is a testament to that. They’re here to ensure our
                  employee experience lives up to our enviable culture, and that everyone has the resources, support and
                  engagement needed to thrive.
                </p>
              </div>
              <div class='box'>
                <h3>Finance</h3>
                <p>
                  With four portfolio companies, we’ve got a lot of numbers to crunch. Our finance team manages all
                  those numbers, from balance sheets to payroll.
                </p>
              </div>
              <div class='box'>
                <h3>Creative & Marketing</h3>
                <p>
                  Part of the secret sauce? Getting the products we build in front of the people who want them most. Our
                  marketing team are experts in digital marketing and the brains behind all-things-creative.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurCompanyTemplate
