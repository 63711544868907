import React from 'react'
import Inner from '../Inner'

function PortfolioTemplate({ data = {} }) {
  return (
    <>
      <Inner>
        {data.subtitle && <h1>{data.subtitle}</h1>}
        <ul className='companies'>
          <li>
            <img src={'/images/portfolio/logo-shift.svg'} alt='Shift' />
          </li>
          <li>
            <img src={'/images/portfolio/logo-rebase.svg'} alt='Rebase' />
          </li>
          <li>
            <img src={'/images/portfolio/logo-leadpages.svg'} alt='Leadpages' />
          </li>
          <li>
            <img src={'/images/portfolio/logo-delivra.svg'} alt='Delivra' />
          </li>
        </ul>
      </Inner>
    </>
  )
}

export default PortfolioTemplate
