import React from 'react'
import htmlToReact from 'react-html-parser'

import Inner from '../Inner'

function PerksTemplate({ data }) {
  const perks = [
    {
      icon: 'fitness',
      desc: 'Health & Wellness<br />Reimbursement',
    },
    {
      icon: 'vacation',
      desc: 'Vacation/Wellness<br/>Days',
    },
    {
      icon: 'financial',
      desc: 'Financial Wellness<br />Program',
    },
    {
      icon: 'piggy',
      desc: '401K / RRSP<br/>Matching',
    },
    {
      icon: 'health',
      desc: 'Extended Healthcare',
    },
    {
      icon: 'development',
      desc: 'Professional<br/>Development',
    },
    {
      icon: 'referral',
      desc: 'Employee Referral<br/>Program',
    },
    {
      icon: 'recognition',
      desc: 'Employee<br />Recognition',
    },
    {
      icon: 'volunteer',
      desc: 'Employee Volunteer<br/>Program',
    },
    {
      icon: 'commute',
      desc: 'Commute to Work<br/>Incentive Program',
    },
    {
      icon: 'bike',
      desc: 'Bike to Work<br/>Incentive Program',
    },
    {
      icon: 'chair',
      desc: 'Home Office Funds',
    },
    {
      icon: 'parental',
      desc: 'Paid Parental Leave',
    },
    {
      icon: 'carbon',
      desc: 'Carbon Neutral<br/>Employee Program',
    },
    {
      icon: 'counselling',
      desc: 'Mental Health/<br/>Employee Assistance',
    },
    {
      icon: 'events',
      desc: 'Flexible Holiday<br/>Program (CAN)',
    },
    {
      icon: 'fruit',
      desc: 'In-Office Snacks & Catered Lunches',
    },
    {
      icon: 'fun',
      desc: 'Fun & Inclusive Environment',
    },
  ]

  return (
    <Inner>
      {data.title && <h2>{data.title}</h2>}
      <div className='perks'>
        {perks.map((perk, idx) => (
          <div className='perk'>
            <div className='icon_wrap'>
              <img src={`/images/perks/perks_${perk.icon}.svg`} alt={perk.desc} />
            </div>
            <div class='desc'>{htmlToReact(perk.desc)}</div>
          </div>
        ))}
      </div>
    </Inner>
  )
}

export default PerksTemplate
