import React, { useState, useEffect } from 'react'

function IntroTemplate({ data }) {
  const [rendered, setRendered] = useState()
  const onGoToSecondPage = (anchor) => {
    if (typeof window !== 'undefined') {
      window.fullpage_api.moveSectionDown()
    }
  }

  useEffect(() => {
    setRendered(true)
  }, [])

  return (
    <>
      <div class='intro_inner_wrapper'>
        <img src={'/images/logo.svg'} alt='Logo' className='logo' />
        <h1>{data.title}</h1>
        {rendered && (
          <button href={`#`} onClick={() => onGoToSecondPage()}>
            <div className='scroll_down_wrapper'>
              <div className='scroll_down'>
                <span className='arrow_down' />
                <span className='scroll_title'>Scroll down</span>
              </div>
            </div>
          </button>
        )}
      </div>
    </>
  )
}

export default IntroTemplate
