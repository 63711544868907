import React from 'react'
import Inner from '../Inner'

function BackstoryTemplate({ data = {}}) {
    return (
        <>
            <Inner flex>
                <div className='text'>
                    {data.title && <h2>{data.title}</h2>}
                    {data.subtitle && <h1>{data.subtitle}</h1>}
                    {data.text && <p>{data.text}</p>}
                </div>
                <div className='offices'>
                    <div className='offices__item'>
                        <img srcset={'/images/backstory/first-office@2x.jpg 2x'} src={'/images/backstory/first-office.jpg'} alt='First Office' />
                        <p className='offices__item__label'>Our first office in Market Square, 2011</p>
                    </div>
                    <div className='offices__item'>
                        <img srcset={'/images/backstory/current-office@2x.jpg 2x'} src={'/images/backstory/current-office.jpg'} alt='Current Office' />
                        <p className='offices__item__label'>Our office at 1515 Douglas Street, 2019</p>
                    </div>
                </div>
            </Inner>
        </>
    )
}

export default BackstoryTemplate
