import classnames from 'classnames'
import React from 'react'

import AssemblyTemplate from './AssemblyTemplate'
import BackgroundImageTemplate from './BackgroundImageTemplate'
import BackstoryTemplate from './BackstoryTemplate'
import BrandTemplate from './BrandTemplate'
import DelivraTemplate from './DelivraTemplate'
import DnaTemplate from './DnaTemplate'
import ElevatorPitchTemplate from './ElevatorPitchTemplate'
import IntroTemplate from './IntroTemplate'
import LeadpagesTemplate from './LeadpagesTemplate'
import OurBuildingTemplate from './OurBuildingTemplate'
import OurCityTemplate from './OurCityTemplate'
import OurCompanyTemplate from './OurCompanyTemplate'
import OurValuesTemplate from './OurValuesTemplate'
import OutroTemplate from './OutroTemplate'
import PortfolioTemplate from './PortfolioTemplate'
import PerksTemplate from './PerksTemplate'
import RebaseTemplate from './RebaseTemplate'
import QuoteTemplate from './QuoteTemplate'
import ShiftTemplate from './ShiftTemplate'
import SolidDarkTemplate from './SolidDarkTemplate'
import SolidWhiteTemplate from './SolidWhiteTemplate'
import VideoTemplate from './VideoTemplate'

const templates = {
  assembly: AssemblyTemplate,
  backgroundImage: BackgroundImageTemplate,
  backstory: BackstoryTemplate,
  brand: BrandTemplate,
  delivra: DelivraTemplate,
  dna: DnaTemplate,
  elevatorPitch: ElevatorPitchTemplate,
  intro: IntroTemplate,
  leadpages: LeadpagesTemplate,
  ourBuilding: OurBuildingTemplate,
  ourCity: OurCityTemplate,
  ourCompany: OurCompanyTemplate,
  ourValues: OurValuesTemplate,
  outro: OutroTemplate,
  perks: PerksTemplate,
  portfolio: PortfolioTemplate,
  quote: QuoteTemplate,
  rebase: RebaseTemplate,
  shift: ShiftTemplate,
  solidDark: SolidDarkTemplate,
  solidWhite: SolidWhiteTemplate,
  video: VideoTemplate,
}

function Template({ className, slide, ...rest }) {
  const mClassName = classnames('Template', className, {
    [`--${slide.template}`]: !!slide.template,
  })

  const CurrentTemplate = templates[slide.template]

  return CurrentTemplate ? <CurrentTemplate className={mClassName} data={slide.templateData} {...rest} /> : ''
}

export default Template
