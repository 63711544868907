import React from 'react'
import Inner from '../Inner'

function OurValuesTemplate({ data }) {
    return (
        <Inner>
            <div className='image'>
                <picture>
                    <source
                        media='(min-width: 768px)'
                        srcSet={`/images/our-values/values-desktop.svg`}
                    />
                    <source
                        media='(max-width: 767px)'
                        srcSet={`/images/our-values/values-mobile.svg`}
                    />
                    <img
                        className='Story__image'
                        src={'/images/our-values/values-desktop.svg'}
                        alt='Our Values'
                    />
                </picture>
            </div>
        </Inner>
    )
}

export default OurValuesTemplate
