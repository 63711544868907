import React from 'react'
import Inner from '../Inner'

function BrandTemplate({ data }) {
    return (
        <Inner>
            {data.title && <h2>{data.title}</h2>}
            <div className='cols'>
                <div className='cols__col'>
                    {data.nameTitle && <h2>{data.nameTitle}</h2>}
                    {data.nameSubtitle && <h1>{data.nameSubtitle}</h1>}
                    {data.nameText && <p>{data.nameText}</p>}
                </div>
                <div className='cols__col'>
                    {data.urlTitle && <h2>{data.urlTitle}</h2>}
                    {data.urlSubtitle && <h1>{data.urlSubtitle}</h1>}
                    {data.urlText && <p>{data.urlText}</p>}
                </div>
            </div>
        </Inner>
    )
}

export default BrandTemplate
