import React from 'react'
import Inner from '../Inner'

function LeadpagesTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                <img src={'/images/leadpages/logo-leadpages.svg'} alt='Leadpages' className='logo' />
                {data.text && <p>{data.text}</p>}
            </div>
        </Inner>
    )
}

export default LeadpagesTemplate
