import classnames from 'classnames'
import React from 'react'

function Inner({ children, className, flex, noMargin, noMarginMobile }) {
    const mClassName = classnames('Inner', className, {
        '--noMargin': noMargin && noMarginMobile === undefined,
        '--noMarginMobile': noMarginMobile,
        '--flex': flex,
    })

    return <div className={mClassName}>{children}</div>
}

export default Inner
