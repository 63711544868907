import React from 'react'
import htmlToReact from 'react-html-parser'

function OutroTemplate({ data }) {
    const onGoToFirstPage = (anchor) => {
        if (typeof window !== 'undefined') {
            window.fullpage_api.moveTo('')
        }
    }

    return (
        <>
            <h1>{htmlToReact(data.title)}</h1>
            <button href={`#`} onClick={() => onGoToFirstPage()}>
                <img src={'/images/logo.svg'} alt='Logo' className='logo' />
            </button>
        </>
    )
}

export default OutroTemplate
