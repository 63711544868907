import React from 'react'
import Inner from '../Inner'

function VideoTemplate({ data }) {
    return (
        <Inner>
            <div className='videoWrap'>
                <video className='bg-video__content' src={data.videoSrcMP4} controls>
                    <source src={data.videoSrcMP4} type='video/mp4' />
                    <source src={data.videoSrcWEBM} type='video/webm' />
                    Your browser is not supported!
                </video>
            </div>
        </Inner>
    )
}

export default VideoTemplate
