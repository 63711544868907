import React from 'react'
import Inner from '../Inner'

function RebaseTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                <img src={'/images/rebase/logo-rebase.svg'} alt='Rebase' className='logo' />
                {data.text && <p>{data.text}</p>}
            </div>
        </Inner>
    )
}

export default RebaseTemplate
