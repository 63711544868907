import classnames from 'classnames'
import React, { useCallback, useState } from 'react'

function Nav({ className, currentSlide, sections, visible }) {
    const [showPull, setShowPull] = useState(false)

    const hClassName = classnames(
        'Nav',
        className,
        {
            '--toggled': showPull,
            '--visible': visible,
        },
        currentSlide.navClass
    )

    const onClickClosePull = useCallback(() => {
        setShowPull(!showPull)
    }, [showPull])

    const onGoToSectionClick = (anchor) => {
        if (typeof window !== 'undefined') {
            window.fullpage_api.moveTo(anchor)
        }
        setShowPull(false)
    }

    const currentSection = currentSlide && currentSlide.section ? currentSlide.section : ''

    return (
        <div className={hClassName}>
            <div className='Nav__top'>
                <div className='Nav__logo'>
                    <svg
                        className='logo'
                        width='183'
                        height='20'
                        xmlns='http://www.w3.org/2000/svg'
                        xlinkHref='http://www.w3.org/1999/xlink'
                    >
                        <defs>
                            <path id='a' d='M.05.487H182.6v19.375H.05z' />
                            <path id='c' d='M.197.405h29.775V19.95H.197z' />
                        </defs>
                        <g fill='none' fill-rule='evenodd'>
                            <g>
                                <path
                                    fill='#FFF'
                                    mask='url(#b)'
                                    d='M182.6 19.862l-7.73-10.696L182.1.488h-4.054l-9.073 10.688V.488h-3.275v19.374h3.275v-3.617l3.685-4.423 6.001 8.04h3.94M7.882 11.088c2.998 0 4.718-1.274 4.718-3.902 0-2.547-1.72-3.765-4.718-3.765H3.276v7.667h4.606zm4.44 8.774L8.77 13.994c-.278.028-.583.028-.888.028H3.276v5.84H0V.488h7.882c4.968 0 7.827 2.407 7.827 6.615 0 3.1-1.443 5.286-4.025 6.282l4.357 6.477h-3.718z'
                                />
                            </g>
                            <path
                                fill='#FFF'
                                d='M39.789.487v2.934H28.895v5.232h10.894v2.933H28.895v5.341H39.83v2.935H25.62V.488h14.169M64.09 10.203c0-3.959-2.97-6.782-6.8-6.782h-4.634v13.506h4.773c3.774 0 6.66-2.794 6.66-6.724zm3.302-.028c0 5.59-4.246 9.687-10.13 9.687H49.38V.488h7.965c5.828 0 10.047 4.068 10.047 9.687zM88.793 14.133c0-1.716-1.526-2.74-4.079-2.74h-4.996v5.562h4.996c2.553 0 4.08-.996 4.08-2.822zm-.75-8.221c0-1.633-1.248-2.519-3.329-2.519h-4.996v5.12h4.996c2.08 0 3.33-.968 3.33-2.601zm3.36-.581c0 2.186-1.305 3.819-3.415 4.317 2.609.443 4.163 2.298 4.163 4.9 0 3.321-2.692 5.314-7.076 5.314h-8.633V.488h8.438c4.025 0 6.522 1.826 6.522 4.843zM109.474 11.088c2.997 0 4.717-1.274 4.717-3.902 0-2.547-1.72-3.765-4.717-3.765h-4.607v7.667h4.607zm4.44 8.774l-3.553-5.868c-.277.028-.583.028-.887.028h-4.607v5.84h-3.276V.488h7.883c4.967 0 7.826 2.407 7.826 6.615 0 3.1-1.443 5.286-4.024 6.282l4.356 6.477h-3.718z'
                            />
                            <g transform='translate(127.014)'>
                                <path
                                    fill='#FFF'
                                    mask='url(#d)'
                                    d='M.197 19.86h3.275V.489H.197zM22.617 3.31c-3.885 0-6.937 2.99-6.937 6.81 0 3.818 3.052 6.835 6.937 6.835 1.943 0 3.941-.885 5.413-2.297l1.942 2.159C28.002 18.755 25.199 20 22.45 20c-5.69 0-10.102-4.29-10.102-9.853 0-5.508 4.468-9.743 10.213-9.743 2.748 0 5.495 1.135 7.381 2.99l-1.913 2.38c-1.416-1.522-3.442-2.464-5.413-2.464'
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                <div class='Nav__hamburger'>
                    <button
                        onClick={onClickClosePull}
                        className={classnames('Nav__hamburger__icon', { '--toggled': showPull })}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
            <div className='Nav__menu'>
                <nav>
                    <ul>
                        {sections.map(([title, anchor], index) => (
                            <li
                                className={classnames('Nav__menu__item', className, {
                                    '--active': currentSection === anchor,
                                })}
                                key={anchor}
                            >
                                <a href={`#${anchor}`} onClick={() => onGoToSectionClick(anchor)}>
                                    <span className='Nav__menu__item__number'>{index + 1}</span>
                                    <span className='Nav__menu__item__title'>{title}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Nav
