import React from 'react'
import Inner from '../Inner'

function OurBuildingTemplate({ data }) {
    return (
        <>
            <Inner>
                {data.title && <h2>{data.title}</h2>}
                <div class="mobile">
                    <ul class="photos">
                        <li><img srcset={'/images/building/building-1-mobile@2x.jpg 2x'} src={'/images/building/building-1-mobile.jpg'} alt='Building' /></li>
                        <li><img srcset={'/images/building/building-2-mobile@2x.jpg 2x'} src={'/images/building/building-2-mobile.jpg'} alt='Building' /></li>
                        <li><img srcset={'/images/building/building-3-mobile@2x.jpg 2x'} src={'/images/building/building-3-mobile.jpg'} alt='Building' /></li>
                        <li><img srcset={'/images/building/building-4-mobile@2x.jpg 2x'} src={'/images/building/building-4-mobile.jpg'} alt='Building' /></li>
                        <li><img srcset={'/images/building/building-5-mobile@2x.jpg 2x'} src={'/images/building/building-5-mobile.jpg'} alt='Building' /></li>
                        <li><img srcset={'/images/building/building-6-mobile@2x.jpg 2x'} src={'/images/building/building-6-mobile.jpg'} alt='Building' /></li>
                    </ul>
                    <ul class="pros">
                        <li><p>Local, Recycled & Renewable Building Materials</p></li>
                        <li><p>Daylight-Responsive Lighting</p></li>
                        <li><p>Low-Energy, High-Air-Quality HVAC System</p></li>
                        <li><p>LEED Platinum Certification</p></li>
                        <li><p>24/7 Complimentary Fitness Facility</p></li>
                        <li><p>2 Secure Bike Storage Rooms, a Bike Tune-Up Station, and private Bicycle Share Service</p></li>
                    </ul>
                </div>
            </Inner>
            <div className="desktop tablet">
                <ul class="squares">
                    <li><img srcset={'/images/building/building-1-desktop@2x.jpg 2x'} src={'/images/building/building-1-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text white"><p>Local, Recycled & Renewable Building Materials</p></div></li>
                    <li><img srcset={'/images/building/building-2-desktop@2x.jpg 2x'} src={'/images/building/building-2-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text"><p>Daylight-Responsive Lighting</p></div></li>
                    <li><div class="text dark"><p>Low-Energy, High-Air-Quality HVAC System</p></div></li>
                    <li><img srcset={'/images/building/building-3-desktop@2x.jpg 2x'} src={'/images/building/building-3-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text dark"><p>LEED Platinum Certification</p></div></li>
                    <li><img srcset={'/images/building/building-4-desktop@2x.jpg 2x'} src={'/images/building/building-4-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><img srcset={'/images/building/building-5-desktop@2x.jpg 2x'} src={'/images/building/building-5-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text white"><p>24/7 Complimentary Fitness Facility</p></div></li>
                    <li><img srcset={'/images/building/building-6-desktop@2x.jpg 2x'} src={'/images/building/building-6-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text"><p>2 Secure Bike Storage Rooms, a Bike Tune-Up Station, and private Bicycle Share Service</p></div></li>
                </ul>

                <ul class="squares ultra-wide">
                    <li><img srcset={'/images/building/building-1-desktop@2x.jpg 2x'} src={'/images/building/building-1-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text white"><p>Local, Recycled & Renewable Building Materials</p></div></li>
                    <li><img srcset={'/images/building/building-2-desktop@2x.jpg 2x'} src={'/images/building/building-2-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text dark"><p>Daylight-Responsive Lighting</p></div></li>
                    <li><img srcset={'/images/building/building-3-desktop@2x.jpg 2x'} src={'/images/building/building-3-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text gray"><p>Low-Energy, High-Air-Quality HVAC System</p></div></li>
                    <li><div class="text dark"><p>LEED Platinum Certification</p></div></li>
                    <li><img srcset={'/images/building/building-4-desktop@2x.jpg 2x'} src={'/images/building/building-4-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text gray"><p>2 Secure Bike Storage Rooms, a Bike Tune-Up Station, and private Bicycle Share Service</p></div></li>
                    <li><img srcset={'/images/building/building-5-desktop@2x.jpg 2x'} src={'/images/building/building-5-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    <li><div class="text white"><p>24/7 Complimentary Fitness Facility</p></div></li>
                    <li><img srcset={'/images/building/building-6-desktop@2x.jpg 2x'} src={'/images/building/building-6-desktop.jpg'} alt='Building' /><div class="shadow"></div></li>
                    
                </ul>
            </div>
        </>
    )
}

export default OurBuildingTemplate
