import React from 'react'
import Inner from '../Inner'

function SolidWhiteTemplate({ data }) {
    return (
        <Inner>
            {data.title && <h2>{data.title}</h2>}
            {data.subtitle && <h1>{data.subtitle}</h1>}
            {data.text && <p>{data.text}</p>}
        </Inner>
    )
}

export default SolidWhiteTemplate
