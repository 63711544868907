import React from 'react'
import Inner from '../Inner'

function DnaTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                {data.title && <h2>{data.title}</h2>}
                {data.subtitle && <h1>{data.subtitle}</h1>}
                {data.text && <p>{data.text}</p>}
            </div>
            <div className='image'>
                <picture>
                    <source
                        media='(min-width: 1200px)'
                        srcSet={`/images/dna/dna-desktop.png 1x, /images/dna/dna-desktop@2x.png 2x`}
                    />
                    <source
                        media='(max-width: 1199px)'
                        srcSet={`/images/dna/dna-tablet.png 1x, /images/dna/dna-tablet@2x.png 2x`}
                    />
                    <img
                        className='Story__image'
                        src={'/images/dna/dna-desktop.png'}
                        alt='DNA'
                    />
                </picture>
            </div>
        </Inner>
    )
}

export default DnaTemplate
