import React from 'react'

import Inner from '../Inner'

function QuoteTemplate({ data }) {
    return (
        <Inner>
            <div className='text'>
                <p>{`\u201C${data.quote}\u201D`}</p>
                <h2>{data.author}</h2>
            </div>
        </Inner>
    )
}

export default QuoteTemplate
